/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import "./index.less";
import {
  Button, Col, Dropdown, message, Modal, Row, Skeleton, Spin, Tabs,
} from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { allocationHistoryListAtom } from "../../../../global/atoms";
import {
  deleteSavedAllocationHistory, getAllocationHistoryList, getAllocationProcess,
} from "../../services/allocation";
import { AllocationHistoryListBlock } from "../../index";
import { IAllocationHistoryListItem } from "../../blocks/AllocationHistoryListBlock";
import { repeat } from "../../../../utils/helpers";
import DartTable from "../../../../components/DartTable";
import getDataViewByCC from "../ReviewCCs/getDataViewByCC";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import paths from "../../../../configs/paths";
import { allocationHistoryListSelector } from "../../../../global/selectors/allocation-history-list-selector";
import CompactSelect from "../../../../components/CompactSelect";
import DartDatepicker from "../../../../components/DartDatepicker";
import { orderTypes } from "../../../../configs/constants";

type TabType = "Last 7 Days" | "Last 30 Days" | "Last 60 Days" | "All";

interface IAllocationHistory {
  searchValue?: string;
  filterBy?: any;
  usingQueryFilters?: boolean;
}

const AllocationHistory: React.FC<IAllocationHistory> = ({
  searchValue,
  filterBy,
  usingQueryFilters,
}) => {
  const history = useHistory();
  const modalContainerRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [modalVisibleForEntity, setModalVisibleForEntity] = useState<IAllocationHistoryListItem | undefined>(undefined);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalActionLoading, setModalActionLoading] = useState(false);
  const [historyEntityDetails, setHistoryEntityDetails] = useState({ status: "request", data: {} });
  const [historyEntityFilters, setHistoryEntityFilters] = useState({
    is_hot: "all",
    start_ship_date: "all",
  });
  const allocationHistory = useRecoilValue(allocationHistoryListSelector);
  const setAllocationHistory = useSetRecoilState(allocationHistoryListAtom);
  const setAllocationProcess = useSetRecoilState(allocationProcessAtom);

  // Initial data load for Allocation History
  useEffect(() => {
    fetchAllocationHistory();
  }, []);

  // When allocation history item is clicked, fetch its date using service called "fetchSpecificAllocationHistory"
  useEffect(() => {
    if (modalVisibleForEntity?.id) {
      fetchSpecificAllocationHistory(modalVisibleForEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisibleForEntity]);

  // Call Service that fetches all allocation history
  const fetchAllocationHistory = async () => {
    setLoading(true);
    await getAllocationHistoryList(allocationHistory, setAllocationHistory);
    setLoading(false);
  };

  // Handler to clear old history item and pass to state new history item
  const handleAllocationDetailsView = (process: IAllocationHistoryListItem) => {
    setHistoryEntityDetails({ status: "initial", data: {} });
    setHistoryEntityFilters({
      is_hot: "all",
      start_ship_date: "all",
    });
    setModalVisibleForEntity(process);
  };

  // Function that sets loading state and calls service for fetching allocation process history list
  const fetchSpecificAllocationHistory = async (process: any) => {
    setModalLoading(true);
    // Call Service that fetches the allocation history
    await getAllocationProcess(
      {
        ...historyEntityDetails,
        data: {
          ...historyEntityDetails?.data,
          orderType: process.shipping_date ? orderTypes.cross_dock : orderTypes.regular,
          shippingDate: process.shipping_date,
        },
      }, setHistoryEntityDetails, process?.id, "cc",
    );
    setModalLoading(false);
  };

  // Function that filters the list of Allocation History using the provided days as "Last X Days"
  const filterHistoryByLastXDays = (days: number, historyGroupedList: { [key: string]: IAllocationHistoryListItem[] }) => {
    const dateCutoff = moment().subtract(days, "days");
    const timeCutoffList: { [key: string]: IAllocationHistoryListItem[] } = {};
    const historyList = Object.values(historyGroupedList);
    for (let i = 0; i < Object.keys(historyGroupedList).length; i += 1) {
      const h = historyList[i];
      const itemDate = h[0].created_at;
      const itemDateMoment = moment(itemDate, "YYYY-MM-DD");
      if (itemDateMoment.diff(dateCutoff, "days") >= 0) {
        timeCutoffList[Object.keys(historyGroupedList)[i]] = h;
      }
    }
    return timeCutoffList;
  };

  // Memoized Function that filters the list by search value
  const filterHistoryBySearchAndFilterBy = useCallback((data: { [key: string]: IAllocationHistoryListItem[] }) => {
    const objToReturn: any = {};
    Object.keys(data).forEach((key) => {
      const filteredBySearch = searchValue ? data[key]?.some((item: IAllocationHistoryListItem) => {
        return Object.keys((item?.cc_and_store || {})).some((cc) => cc.toLowerCase().includes(searchValue.toLowerCase()))
          || item?.warehouse_id?.toLowerCase().includes(searchValue.toLowerCase())
          || item?.user_email?.toLowerCase().includes(searchValue.toLowerCase())
          || item?.status?.toLowerCase().includes(searchValue.toLowerCase());
      }) : true;
      const filterByProperties = usingQueryFilters ? (
        data[key]?.some((item: IAllocationHistoryListItem) => {
          return Object.keys((item?.cc_and_store || {})).some((cc) => (filterBy?.cc ? cc.toLowerCase().includes(filterBy?.cc?.toLowerCase()) : true));
        })
      ) : true;
      if (data[key]?.length > 0 && filteredBySearch && filterByProperties) {
        objToReturn[key] = data[key];
      }
    });
    return objToReturn || {};
  }, [searchValue, filterBy?.cc, usingQueryFilters]);

  // Function that decides the filter "Last X Days" value to later on filter and get the data
  const dataGetter = (tab: TabType) => {
    switch (tab) {
      case "Last 7 Days":
        return filterHistoryByLastXDays(7, filterHistoryBySearchAndFilterBy(allocationHistory?.data));
      case "Last 30 Days":
        return filterHistoryByLastXDays(30, filterHistoryBySearchAndFilterBy(allocationHistory?.data));
      case "Last 60 Days":
        return filterHistoryByLastXDays(60, filterHistoryBySearchAndFilterBy(allocationHistory?.data));
      case "All":
      default:
        return filterHistoryBySearchAndFilterBy(allocationHistory?.data);
    }
  };

  // Shows Tab actual UI and data or "loader skeletons" in case data is still being processed
  const tabDataOrLoading = (tabData?: any) => {
    if (loading) {
      return (
        <Row
          className="skeleton-wrapper"
          gutter={[24, 24]}
        >
          {repeat((
            <Col
              span={24}
              xl={24}
              xxl={24}
            >
              <Skeleton.Input
                className="skeleton-item"
                active
              />
            </Col>
          ), 7)}
        </Row>
      );
    }
    return tabData;
  };

  // Memoized Modal component that displays the allocation history details
  const allocationHistoryModal = useMemo(() => {
    // Get table data according to specific details
    const tableData = getDataViewByCC({
      allocationProcess: historyEntityDetails,
      selectedFilters: {
        view: "cc", group: "none", is_hot: historyEntityFilters?.is_hot, start_ship_date: historyEntityFilters?.start_ship_date,
      },
      editAllowed: false,
      highlightCCs: usingQueryFilters && filterBy?.cc ? [filterBy?.cc] : undefined,
      highlightStores: usingQueryFilters && filterBy?.store_ids ? { ids: filterBy?.store_ids, numbers: filterBy?.store_ids } : undefined,
    });

    // Decide modal title according to count of CCs, date allocation was done and author who did the allocation
    const getModalTitle = () => {
      const ccCount = (historyEntityDetails?.data as any)?.processData?.length || 0;
      const count = `${ccCount} CC${ccCount === 1 ? "" : "s"} `;
      const author = `Allocated by ${modalVisibleForEntity?.user_name || modalVisibleForEntity?.user_email || "Dart User"} on `;
      const when = moment(modalVisibleForEntity?.updated_at || modalVisibleForEntity?.created_at).format("MM/DD/YYYY | hh:mm A");
      const total = ((historyEntityDetails?.data || { processData: [] }) as any)?.processData?.reduce((acc: any, cur: any) => {
        return acc + cur.qty_sum;
      }, 0);

      const isCrossDock = modalVisibleForEntity?.shipping_date;

      const isProduction = process.env.REACT_APP_BASE_URL?.indexOf(".dev.") === -1; // @TODO: temp check to remove from prod

      return (
        <div className="modal-header">
          <div className="details-header">
            <span>
              <b>{count}</b>
              {author + when}
            </span>
            <div className="filters">
              <span>
                {"Warehouse ID: "}
                <b>{modalVisibleForEntity?.warehouse_id}</b>
              </span>
              <span>
                {"Brand: "}
                <b>{modalVisibleForEntity?.brand}</b>
              </span>
              <span>
                {"Channel: "}
                <b>{modalVisibleForEntity?.channel}</b>
              </span>
              <span>
                {"Total Units: "}
                <b>{total || "N/A"}</b>
              </span>
            </div>
          </div>

          {!isProduction ? (
            <>
              {isCrossDock ? (
                <div className="filter-allocation-button-wrap">
                  <div className="filter-input-wrapper">
                    <span className="label">Cross-Dock Order</span>
                  </div>
                  <div className="vertical-splitter" />
                  <div className="filter-input-wrapper">
                    <span className="label">Ship Date</span>
                    <DartDatepicker
                      value={moment(modalVisibleForEntity?.shipping_date)}
                      disabled
                    />
                  </div>
                </div>
              ) : (
                <div className="filter-allocation-button-wrap">
                  <div className="filter-input-wrapper">
                    <span className="label">Is Hot</span>
                    <CompactSelect
                      canClear={false}
                      selectOptions={[
                        { value: "all", label: "Show All" },
                        { value: "hot", label: "Hot only" },
                        { value: "not_hot", label: "Not Hot only" },
                      ]}
                      value={{ value: historyEntityFilters.is_hot }}
                      onChange={(value) => {
                        setHistoryEntityFilters((prev: any) => {
                          return {
                            ...prev,
                            is_hot: value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="vertical-splitter" />
                  <div className="filter-input-wrapper">
                    <span className="label">Start Ship Date</span>
                    <DartDatepicker
                      value={historyEntityFilters?.start_ship_date && historyEntityFilters?.start_ship_date !== "all"
                        ? moment(historyEntityFilters?.start_ship_date)
                        : undefined}
                      onChange={(date) => {
                        setHistoryEntityFilters((prev) => {
                          return {
                            ...prev,
                            start_ship_date: date ? moment(date).format("YYYY-MM-DD") : "all",
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : ""}
        </div>
      );
    };

    const handleContinueClick = async () => {
      try {
        if (modalVisibleForEntity?.id) {
          setModalActionLoading(true);
          await getAllocationProcess(
            {
              status: "request",
              data: {
                processId: modalVisibleForEntity?.id || undefined,
                processData: [],
                processStatus: "in_progress",
                orderType: modalVisibleForEntity?.shipping_date ? orderTypes.cross_dock : orderTypes.regular,
                shippingDate: modalVisibleForEntity?.shipping_date,
              },
            }, setAllocationProcess, modalVisibleForEntity!.id, "cc", false,
          );
          message.success("Allocation Process successfully resumed");
          history.push(paths.review_ccs);
          setModalActionLoading(false);
        } else {
          message.error("Allocation Process not found");
        }
      } catch (e) {
        message.error("Allocation Process can not be resumed");
      }
    };

    const handleDeleteClick = async () => {
      setModalActionLoading(true);
      await deleteSavedAllocationHistory(
        allocationHistory, setAllocationHistory, modalVisibleForEntity?.id || 0, () => {
          setModalVisibleForEntity(undefined);
          setModalActionLoading(false);
        },
      );
    };

    return (
      <Modal
        visible={!!modalVisibleForEntity}
        closable
        destroyOnClose
        title={modalLoading ? <div className="details-header">Loading Allocation History...</div> : getModalTitle()}
        maskClosable
        wrapClassName="allocation-history-details"
        onCancel={() => {
          setModalVisibleForEntity(undefined);
          setHistoryEntityDetails({ status: "initial", data: {} });
        }}
        footer={false}
        width="100%"
        getContainer={modalContainerRef.current}
      >
        <div className="review-ccs-wrapper ccs">
          <DartTable
            loading={modalLoading}
            key="review-cc"
            withBorderTop
            columns={tableData.columns}
            data={tableData.data}
            width="100%"
            height={tableData.height}
            fixed
            wrapperClassName="review-ccs-table"
            headerHeight={tableData.headerHeight}
            rowHeight={tableData.rowHeight}
            childHeight={tableData.childHeight}
            expandable
            expandColumnKey={tableData.expandColumnKey}
            hasExpandAll
            header={modalVisibleForEntity?.status === "SAVED" || modalVisibleForEntity?.status === "FAILED" ? (
              <div className={`resume-delete-allocation-button-wrap ${modalActionLoading ? "loading" : ""}`}>
                {modalActionLoading ? <Spin /> : (
                  <>
                    {modalVisibleForEntity?.status !== "FAILED" ? (
                      <Dropdown.Button
                        trigger={["click"]}
                        overlay={(
                          <Button
                            danger
                            onClick={handleDeleteClick}
                          >
                            Delete Allocation
                          </Button>
                        )}
                        onClick={handleContinueClick}
                      >
                        Resume Allocation
                      </Dropdown.Button>
                    ) : (
                      <Button
                        onClick={handleContinueClick}
                      >
                        Resume Allocation
                      </Button>
                    )}
                  </>
                )}
              </div>
            ) : undefined}
          />
        </div>
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisibleForEntity, modalLoading, modalActionLoading,
    historyEntityFilters, filterBy?.cc, filterBy?.store_ids, usingQueryFilters]);

  return (
    <div
      className="allocation-history-wrapper"
      ref={modalContainerRef}
    >
      <Tabs
        defaultActiveKey="last_7_days"
        destroyInactiveTabPane
      >
        <Tabs.TabPane
          tab="Last 7 Days"
          key="last_7_days"
        >
          {tabDataOrLoading((
            <AllocationHistoryListBlock
              list={dataGetter("Last 7 Days")}
              onListItemSelect={handleAllocationDetailsView}
              searchValue={searchValue}
              timeGap="Last 7 Days"
            />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Last 30 Days"
          key="last_30_days"
        >
          {tabDataOrLoading((
            <AllocationHistoryListBlock
              list={dataGetter("Last 30 Days")}
              onListItemSelect={handleAllocationDetailsView}
              searchValue={searchValue}
              timeGap="Last 30 Days"
            />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Last 60 Days"
          key="last_60_days"
        >
          {tabDataOrLoading((
            <AllocationHistoryListBlock
              list={dataGetter("Last 60 Days")}
              onListItemSelect={handleAllocationDetailsView}
              searchValue={searchValue}
              timeGap="Last 60 Days"
            />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="All"
          key="All"
        >
          {tabDataOrLoading((
            <AllocationHistoryListBlock
              list={dataGetter("All")}
              onListItemSelect={handleAllocationDetailsView}
              searchValue={searchValue}
              timeGap="All"
            />
          ))}
        </Tabs.TabPane>
      </Tabs>
      {allocationHistoryModal}
    </div>
  );
};

export default AllocationHistory;
