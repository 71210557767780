import { MinusOutlined } from "@ant-design/icons";
import React from "react";
import moment from "moment";
import { DatePicker, Tag } from "antd";
import DartTable from "../../../../components/DartTable";
import EditableCell from "../../../../components/EditableCell";
import { faker } from "../../../../utils/helpers";
import sortBySize from "../../utils/sortBySize";
import SelectableCell from "../../../../components/SelectableCell";
import Icon from "../../../../components/Icon";
import { orderTypes } from "../../../../configs/constants";

type UpdateAllocationDataType = (process_id: number, sku: string, store_id: string, qty: number) => void;
type UpdateAllocationDataForAllColumnsType = (process_id: number, skus: any[], store_id: string, total: number) => void;
type UpdateHotSKUsForAllColumnsType = (process_id: number, skus: any[], store_id: string, is_hot: boolean) => void;
type UpdateStartShipDateSKUsForAllColumnsType = (process_id: number, skus: any[], store_id: string, start_ship_date: string | null) => void;

export const getDataViewByCC = (dataProps: {
  allocationProcess: any,
  selectedFilters?: any,
  onUpdateAllocationData?: UpdateAllocationDataType,
  editAllowed?: boolean,
  onUpdateAllocationDataForAllColumns?: UpdateAllocationDataForAllColumnsType,
  expandedRows?: string[],
  onExpandToggle?: (toggleValue: boolean) => void,
  selectedRows?: string[],
  onSelectToggle?: (selectedRowData: any) => void,
  onUpdateHotSKUs?: UpdateHotSKUsForAllColumnsType,
  onUpdateStartShipDateSKUs?: UpdateStartShipDateSKUsForAllColumnsType,
  highlightCCs?: string[],
  highlightStores?: { ids?: string[], numbers?: string[] },
}) => {
  const fetchedData = dataProps?.allocationProcess?.data?.processData;
  const returnData = [];
  let additionalColumns = 0;

  const simplifyName = (name?: string) => {
    return name?.toLowerCase().replace(/[\W_]+/g, "_");
  };

  for (let i = 0; i < fetchedData?.length; i += 1) {
    const row = fetchedData[i];

    const groupNestedByStoreId = () => {
      const { nested } = row;
      const stores: any = {};
      for (let a = 0; a < nested.length; a += 1) {
        const changedName = `${simplifyName(nested[a]?.store_name)}_${nested[a]?.store_id}`;
        if (!stores[changedName]) {
          stores[changedName] = [nested[a]];
        } else {
          stores[changedName] = [...stores[changedName], nested[a]];
        }
      }
      return stores;
    };

    const generateChildData = (forCC?: string) => {
      const stores = groupNestedByStoreId();
      const storeNames = Object.keys(stores).sort();
      const childData = [];
      for (let a = 0; a < storeNames.length; a += 1) {
        const storeName = storeNames[a];

        const sortedStoreNameData = sortBySize(stores[storeName]);

        const total = sortedStoreNameData.reduce((acc: number, cur: any) => {
          return acc + cur.allocated_qty;
        }, 0);

        let shouldFilterOut = false;
        if (dataProps?.selectedFilters) {
          if (dataProps?.selectedFilters?.is_hot === "hot") {
            if (sortedStoreNameData[0]?.is_hot === false) {
              shouldFilterOut = true;
            }
          } else if (dataProps?.selectedFilters?.is_hot === "not_hot") {
            if (sortedStoreNameData[0]?.is_hot === true) {
              shouldFilterOut = true;
            }
          }

          if (dataProps?.selectedFilters?.start_ship_date) {
            if (dataProps?.selectedFilters?.start_ship_date !== "all" && !!dataProps?.selectedFilters?.start_ship_date) {
              if (sortedStoreNameData[0]?.start_ship_date !== dataProps?.selectedFilters?.start_ship_date) {
                shouldFilterOut = true;
              }
            }
          }
        }

        if (!shouldFilterOut) {
          childData.push({
            uKey: `${sortedStoreNameData[0]?.store_id?.toString()}_${total}`,
            id: sortedStoreNameData[0]?.store_id,
            uuid: sortedStoreNameData[0]?.store_name,
            number: sortedStoreNameData[0]?.store_number,
            forCC,
            skus: sortedStoreNameData,
            totalRow: total,
          });
        }
      }
      return childData;
    };

    const generateChildColumns = () => {
      const stores = groupNestedByStoreId();
      const anchorStore = stores[Object.keys(stores)[0]];
      const childColumns: any[] = [
        {
          key: "uuid",
          dataIndex: "uuid",
          title: "",
          width: 400,
          flexGrow: 2,
          cellRenderer: ({ rowData }: any) => {
            const highlight = dataProps?.highlightCCs && dataProps?.highlightStores
              && dataProps?.highlightCCs?.indexOf(rowData?.forCC) > -1
              && (dataProps?.highlightStores?.ids?.includes(rowData.id?.toString()));
            return (
              <div className={`review-ccs-table-cell uuid-child-cell ${highlight ? "highlight" : ""}`}>
                {rowData.id}
                {" | "}
                {rowData.uuid || "N/A"}
              </div>
            );
          },
        },
        ...(dataProps?.allocationProcess?.data?.orderType !== orderTypes.cross_dock
        && (dataProps.onUpdateHotSKUs || dataProps?.onUpdateStartShipDateSKUs) ? [
            {
              key: "is_hot",
              dataIndex: "is_hot",
              title: "Is Hot",
              width: 60,
              flexGrow: 1,
              align: "center",
              cellRenderer: ({ rowData }: any) => {
                const is_hot = (rowData?.skus || [])?.[0]?.is_hot;

                return (
                  <div className={`review-ccs-table-cell ${dataProps.editAllowed ? "cursor-pointer" : "cursor-not-allowed"}`}>
                    {is_hot ? (
                      <Icon
                        name="Check"
                        color="#E87324"
                        {...(dataProps.editAllowed ? {
                          onClick: () => dataProps?.onUpdateHotSKUs?.(
                            rowData.skus?.[0]?.process_id, rowData.skus, rowData?.id, false,
                          ),
                        } : {})}
                      />
                    ) : (
                      <Icon
                        name="Close"
                        color="#959A9D"
                        {...(dataProps.editAllowed ? {
                          onClick: () => dataProps?.onUpdateHotSKUs?.(
                            rowData.skus?.[0]?.process_id, rowData.skus, rowData?.id, true,
                          ),
                        } : {})}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              key: "start_ship_date",
              dataIndex: "start_ship_date",
              title: "Start Ship Date",
              width: 150,
              flexGrow: 1,
              align: "center",
              cellRenderer: ({ rowData }: any) => {
                const startShipDate = (rowData?.skus || [])?.[0]?.start_ship_date;

                return (
                  <div className="review-ccs-table-cell">
                    {dataProps?.editAllowed ? (
                      <DatePicker
                        disabled={!dataProps?.editAllowed}
                        placeholder="N/A"
                        style={{ width: "130px" }}
                        onChange={(dateValue) => dataProps?.onUpdateStartShipDateSKUs?.(
                          rowData.skus?.[0]?.process_id,
                          rowData.skus,
                          rowData?.id,
                          dateValue ? dateValue.format("YYYY-MM-DD") : null,
                        )}
                        value={!startShipDate ? null : moment(startShipDate)}
                        allowClear
                      />
                    ) : <span>{startShipDate || "-"}</span>}
                  </div>
                );
              },
            },
          ] : []),
        {
          key: "total",
          dataIndex: "total",
          title: "Total",
          width: 150,
          flexGrow: 2,
          align: "center",
          cellRenderer: ({ rowData }: any) => {
            return (
              <div
                className="review-ccs-table-cell"
                key={rowData?.uKey}
              >
                <EditableCell
                  editable={dataProps?.editAllowed}
                  cellData={rowData?.totalRow || 0}
                  onOk={(qty) => dataProps?.onUpdateAllocationDataForAllColumns?.(
                    rowData.skus?.[0]?.process_id, rowData.skus, rowData.id, qty,
                  )}
                  min={rowData?.skus?.length}
                  cancelOnBlur
                />
              </div>
            );
          },
        }];

      const sortedAnchorStoreData = sortBySize(anchorStore);

      for (let a = 0; a < sortedAnchorStoreData.length; a += 1) {
        childColumns.push({
          key: sortedAnchorStoreData[a]?.size_value || faker("number", 100000000).toString(),
          dataIndex: sortedAnchorStoreData[a]?.size_value,
          title: sortedAnchorStoreData[a]?.sku, // Change with sku if needed
          width: 140,
          flexGrow: 1,
          align: "center",
          cellRenderer: ({ rowData }: any) => {
            const renderValue = rowData.skus.find((s:any) => {
              return s.store_id === rowData.id && s.size_value === sortedAnchorStoreData[a]?.size_value;
            })?.allocated_qty;
            const processId = rowData.skus?.[0]?.process_id;
            return (
              <div
                className="review-ccs-table-cell"
                key={rowData?.uKey}
              >
                <EditableCell
                  editable={dataProps?.editAllowed}
                  cellData={renderValue > 0 ? renderValue : 0}
                  onOk={(qty) => dataProps?.onUpdateAllocationData?.(
                    processId, sortedAnchorStoreData[a]?.sku, rowData.id, qty,
                  )}
                  min={0}
                  cancelOnBlur
                />
              </div>
            );
          },
        });
      }
      return childColumns;
    };

    if (additionalColumns < Object.keys(row.qty_sku).length) {
      additionalColumns = Object.keys(row.qty_sku).length;
    }

    // Modify qty_sku object to have size_value (last "-" separated value)
    const modifiedRowQtySku = Object.keys(row.qty_sku).map((key) => {
      return {
        key,
        value: row.qty_sku[key],
        size_value: key.substring(key.lastIndexOf("-") + 1),
      };
    });

    // Sort by size_value with util function
    const sortedRowQtySku = sortBySize(modifiedRowQtySku);

    const objSortedRowQtySku: any = {};

    // Re-create qty_sku object as size_value sorted object
    for (let a = 0; a < sortedRowQtySku.length; a += 1) {
      objSortedRowQtySku[sortedRowQtySku[a].key] = sortedRowQtySku[a].value;
    }

    const childData = generateChildData(row.cc);

    if (childData?.length) {
      returnData.push({
        id: `${row.cc}_${row.warehouse_id}_${row.isd}`,
        uuid: row.cc,
        highlight: dataProps?.highlightCCs && dataProps?.highlightCCs?.indexOf(row.cc) > -1,
        date: row.isd,
        warehouse_id: row.warehouse_id,
        division: row.division,
        department: row.department,
        description: row.description,
        class: row.class || row.klass,
        total: row.qty_sum,
        extraCols: objSortedRowQtySku, // This is the extra columns for the sku
        childRender: (
          <div className="expanded-row-child">
            <DartTable
              fixed
              width="100%"
              height={300}
              headerHeight={50}
              rowHeight={40}
              data={childData}
              columns={generateChildColumns()}
            />
          </div>
        ),
      });
    }
  }

  let groups:string[] = [];

  const groupData = [];
  if (dataProps?.selectedFilters?.group === "division") {
    groups = returnData.map((d:any) => d.division).filter((
      v:any, i:any, a:any,
    ) => a.indexOf(v) === i);
    for (let g = 0; g < groups.length; g += 1) {
      const division = groups[g];
      const group = returnData.filter((d:any) => d.division === division);
      groupData.push(group);
    }
  } else if (dataProps?.selectedFilters?.group === "department") {
    groups = returnData.map((d:any) => d.department).filter((
      v:any, i:any, a:any,
    ) => a.indexOf(v) === i);
    for (let g = 0; g < groups.length; g += 1) {
      const dept = groups[g];
      const group = returnData.filter((d:any) => d.department === dept);
      groupData.push(group);
    }
  } else if (dataProps?.selectedFilters?.group === "class") {
    groups = returnData.map((d:any) => d.class).filter((
      v:any, i:any, a:any,
    ) => a.indexOf(v) === i);
    for (let g = 0; g < groups.length; g += 1) {
      const gtm = groups[g];
      const group = returnData.filter((d:any) => d.class === gtm);
      groupData.push(group);
    }
  } else {
    groups = [];
  }

  // MAIN RETURN POINT
  return {
    groups,
    columns: ccsByDivisionColumns({
      additionalColumns,
      editAllowed: dataProps?.editAllowed && dataProps?.allocationProcess?.data?.orderType !== orderTypes.cross_dock,
      expandedRows: dataProps?.expandedRows,
      onExpandToggle: dataProps?.onExpandToggle,
      selectedRows: dataProps?.selectedRows,
      onSelectToggle: dataProps?.onSelectToggle,
    }),
    data: groups?.length > 0 ? groupData : returnData,
    expandColumnKey: "id",
    height: groups.length === 0 ? window.innerHeight - 318 : 500,
    childHeight: 300,
    rowHeight: 110,
    headerHeight: 60,
  };
};

export const ccsByDivisionColumns = (props: {
  editAllowed?: boolean,
  expandedRows?: string[],
  onExpandToggle?: (toggleValue: boolean) => void,
  selectedRows?: string[],
  onSelectToggle?: (selectedRowData: any) => void,
  additionalColumns?: number,
}): Record<string, any>[] => [
  ...(props?.editAllowed && props?.onSelectToggle ? [{
    key: "checkbox",
    dataIndex: "checkbox",
    title: "",
    width: 50,
    flexGrow: 1,
    cellRenderer: (rowProps: any) => {
      return (
        <div className="readiness-table-cell uuid-cell">
          {SelectableCell({
            ...rowProps,
            column: {
              selectedRowKeys: props?.selectedRows || [],
              onChange: (selectionData: any) => props?.onSelectToggle?.(selectionData) || false,
              rowKey: rowProps?.rowData?.id,
            },
          }, true)}
        </div>
      );
    },
  }] : [{
    key: "id",
    dataIndex: "id",
    title: "",
    width: 36,
    flexGrow: 1,
    cellRenderer: () => {
      return "";
    },
  }]),
  {
    key: "uuid",
    dataIndex: "uuid",
    title: "",
    width: 170,
    flexGrow: 2,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell uuid-parent-cell">
          <div className={`uuid ${rowData.highlight ? "highlight" : ""}`}>
            {rowData.uuid || NAComponent}
          </div>
          {rowData?.date && (
            <div className="date">
              {rowData?.warehouse_id && <Tag>{rowData?.warehouse_id}</Tag>}
              {moment(rowData?.date).format("MMM YYYY")}
            </div>
          )}
        </div>
      );
    },
  },
  ...(props?.editAllowed ? [{
    key: "id",
    dataIndex: "id",
    title: "",
    width: 36,
    flexGrow: 1,
    cellRenderer: () => {
      return "";
    },
  }] : []),
  {
    key: "division",
    dataIndex: "division",
    title: "Division",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {rowData?.division || NAComponent}
        </div>
      );
    },
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {rowData?.department || NAComponent}
        </div>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    width: 150,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {rowData.description || NAComponent}
        </div>
      );
    },
  },
  {
    key: "class",
    dataIndex: "class",
    title: "Class",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {rowData.class || NAComponent}
        </div>
      );
    },
  },
  {
    key: "total",
    dataIndex: "total",
    title: "Total",
    width: 80,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {rowData?.total || NAComponent}
        </div>
      );
    },
  },
  ...Array(props.additionalColumns).fill(0).map((v, i) => {
    return {
      key: `sku${i}`,
      dataIndex: `sku${i}`,
      title: "",
      width: 170,
      flexGrow: 1,
      align: "center",
      cellRenderer: ({ rowData }: any) => {
        const innerTitle = Object.keys(rowData.extraCols || {})[i];
        const value = Object.values(rowData.extraCols || {})[i];
        return (
          <div className="review-table-cell">
            <>
              <div className="inline-header">{innerTitle}</div>
              {value || NAComponent}
            </>
          </div>
        );
      },
    };
  }),
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default getDataViewByCC;
