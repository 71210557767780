import { MinusOutlined } from "@ant-design/icons";
import { DatePicker, Tag } from "antd";
import moment from "moment";
import React from "react";
import DartTable from "../../../../components/DartTable";
import EditableCell from "../../../../components/EditableCell";
import Icon from "../../../../components/Icon";
import { faker } from "../../../../utils/helpers";
import sortBySize from "../../utils/sortBySize";
import SelectableCell from "../../../../components/SelectableCell";
import { orderTypes } from "../../../../configs/constants";

type UpdateAllocationDataType = (process_id: number, sku: string, store_id: string, qty: number) => void;
type UpdateAllocationDataForAllColumnsType = (process_id: number, skus: any[], store_id: string, total: number) => void;
type UpdateHotSKUsForAllColumnsType = (process_id: number, skus: any[], store_id: string, is_hot: boolean) => void;
type UpdateStartShipDateSKUsForAllColumnsType = (process_id: number, skus: any[], store_id: string, start_ship_date: string | null) => void;

export const getDataViewByStore = (dataProps: {
  allocationProcess: any,
  selectedFilters?: any,
  onUpdateAllocationData?: UpdateAllocationDataType,
  editAllowed?: boolean,
  onUpdateAllocationDataForAllColumns?: UpdateAllocationDataForAllColumnsType,
  expandedRows?: string[],
  onExpandToggle?: (toggleValue: boolean) => void,
  selectedRows?: number[],
  onSelectToggle?: (selectedRowData: any) => void,
  onUpdateHotSKUs?: UpdateHotSKUsForAllColumnsType,
  onUpdateStartShipDateSKUs?: UpdateStartShipDateSKUsForAllColumnsType,
}) => {
  const fetchedData = dataProps?.allocationProcess?.data?.processData;
  const returnData = [];
  let additionalColumns = 0;

  const groupNestedByStoreId = (row: any) => {
    const { nested } = row;
    const ccs: any = {};
    for (let a = 0; a < nested.length; a += 1) {
      if (!ccs[nested[a]?.cc]) {
        ccs[nested[a]?.cc] = [nested[a]];
      } else {
        ccs[nested[a]?.cc] = [...ccs[nested[a]?.cc], nested[a]];
      }
    }
    return ccs;
  };

  const generateChildData = (row: any) => {
    const ccs = groupNestedByStoreId(row);
    const ccKeys = Object.keys(ccs);
    const childData = [];
    for (let a = 0; a < ccKeys.length; a += 1) {
      const ccKey = ccKeys[a];

      if (additionalColumns < ccs[ccKey].length) {
        additionalColumns = ccs[ccKey].length;
      }

      const sortedCCs = sortBySize(ccs[ccKey]);

      let shouldFilterOut = false;
      if (dataProps?.selectedFilters) {
        if (dataProps?.selectedFilters?.is_hot === "hot") {
          if (sortedCCs?.[0]?.is_hot === false) {
            shouldFilterOut = true;
          }
        } else if (dataProps?.selectedFilters?.is_hot === "not_hot") {
          if (sortedCCs?.[0]?.is_hot === true) {
            shouldFilterOut = true;
          }
        }

        if (dataProps?.selectedFilters?.start_ship_date) {
          if (dataProps?.selectedFilters?.start_ship_date !== "all" && !!dataProps?.selectedFilters?.start_ship_date) {
            if (sortedCCs?.[0]?.start_ship_date !== dataProps?.selectedFilters?.start_ship_date) {
              shouldFilterOut = true;
            }
          }
        }
      }

      if (!shouldFilterOut) {
        childData.push({
          id: ccKey || faker("number", 100000000).toString(),
          uuid: sortedCCs?.[0]?.cc,
          division: sortedCCs?.[0]?.division,
          department: sortedCCs?.[0]?.department,
          description: sortedCCs?.[0]?.description,
          class: sortedCCs?.[0]?.class || sortedCCs?.[0]?.klass,
          total: sortedCCs.reduce((acc: any, curr: any) => acc + curr.allocated_qty, 0),
          warehouse_id: row.warehouse_id,
          is_hot: sortedCCs?.[0]?.is_hot,
          start_ship_date: sortedCCs?.[0]?.start_ship_date,
          skus: sortedCCs,
        });
      }
    }
    return childData;
  };

  const generateChildColumns = (row: any) => {
    const childColumns: any[] = storeByTierChildColumns({
      row,
      onTotalUpdate: (
        process_id, skus, store_id, total,
      ) => dataProps?.onUpdateAllocationDataForAllColumns?.(
        process_id, skus, store_id, total,
      ),
      onHotUpdate: (
        process_id, skus, store_id, is_hot,
      ) => dataProps?.onUpdateHotSKUs?.(
        process_id, skus, store_id, is_hot,
      ),
      onStartShipDateUpdate: (
        process_id, skus, store_id, start_ship_date,
      ) => dataProps?.onUpdateStartShipDateSKUs?.(
        process_id, skus, store_id, start_ship_date,
      ),
      editable: dataProps?.editAllowed || false,
      orderType: dataProps?.allocationProcess?.data?.orderType,
    });
    for (let a = 0; a < additionalColumns; a += 1) {
      childColumns.push({
        key: (a || faker("number", 100000000)).toString(),
        dataIndex: a.toString(),
        title: "",
        width: 190,
        flexGrow: 1,
        align: "center",
        editable: true,
        cellRenderer: ({ rowData }: any) => {
          return (
            <div className="review-ccs-table-cell">
              <div className="inline-header">{rowData?.skus?.[a]?.sku || NAComponent}</div>
              {rowData?.skus?.[a]?.sku ? (
                <EditableCell
                  editable={dataProps?.editAllowed}
                  cellData={rowData?.skus?.[a]?.allocated_qty > 0 ? rowData?.skus?.[a]?.allocated_qty : 0}
                  onOk={(qty) => dataProps?.onUpdateAllocationData?.(
                    rowData?.skus?.[a].process_id, rowData?.skus?.[a].sku, rowData?.skus?.[a].store_id, qty,
                  )}
                  min={0}
                />
              ) : NAComponent }
            </div>
          );
        },
      });
    }
    return childColumns;
  };

  for (let i = 0; i < fetchedData?.length; i += 1) {
    const row = fetchedData[i];

    const childData = generateChildData(row);

    if (childData?.length) {
      returnData.push({
        id: row.store_id || faker("number", 100000000).toString(),
        tier: row.store_tier,
        uuid: row.store_name,
        unit_qty: row.qty_sum,
        cc_qty: countUniqueCCs(row),
        nested: row.nested,
        childRender: (
          <div className="expanded-row-child">
            <DartTable
              fixed
              width="100%"
              height={400}
              headerHeight={0}
              rowHeight={40}
              data={generateChildData(row)}
              columns={generateChildColumns(row)}
            />
          </div>
        ),
      });
    }
  }

  const groups:string[] = dataProps?.selectedFilters?.group === "tier" ? [] : [];

  const sortedReturnData = [...(returnData || [])]?.sort((a: any, b: any) => {
    if (a.uuid < b.uuid) return -1;
    if (a.uuid > b.uuid) return 1;
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });

  const groupData: any = [];
  if (dataProps?.selectedFilters?.group === "tier") {
    const tierData = transformDataWithTierPivot(
      sortedReturnData, generateChildColumns, generateChildData,
    );
    if (Object.keys(tierData || {}).length > 0) {
      const sortedTierData: any = {};
      Object.keys(tierData)
        .sort()
        .forEach((v) => {
          sortedTierData[v] = tierData[v];
        });
      for (let i = 0; i < Object.keys(sortedTierData || {}).length; i += 1) {
        const tier = Object.keys(sortedTierData)[i];
        const tierRows = Object.values(sortedTierData[tier]);
        groups.push(tier);
        groupData.push(tierRows);
      }
    }
  }

  // MAIN RETURN POINT
  return {
    groups,
    columns: storeByTierColumns({
      editAllowed: dataProps?.editAllowed && dataProps?.allocationProcess?.data?.orderType !== orderTypes.cross_dock,
      expandedRows: dataProps?.expandedRows,
      onExpandToggle: dataProps?.onExpandToggle,
      selectedRows: dataProps?.selectedRows,
      onSelectToggle: dataProps?.onSelectToggle,
    }),
    data: groups?.length > 0 ? groupData : sortedReturnData,
    expandColumnKey: "id",
    height: groups.length === 0 ? window.innerHeight - 318 : 500,
    childHeight: 400,
    rowHeight: 56,
    headerHeight: 60,
  };
};

const countUniqueCCs = (row: any) => {
  const skus = Object.keys(row.qty_sku);
  const unique:string[] = [];
  for (let a = 0; a < skus.length; a += 1) {
    const actualCC = skus[a].substring(0, skus[a].lastIndexOf("-"));
    if (!unique.includes(actualCC)) {
      unique.push(actualCC);
    }
  }
  return unique.length;
};

const transformDataWithTierPivot = (
  data: any, generateColumnsFn: any, generateChildrenFn: any,
) => {
  const tierData: any = {};
  for (let i = 0; i < data.length; i += 1) {
    const storeAnchor = data[i];
    for (let o = 0; o < storeAnchor.nested.length; o += 1) {
      const ccAnchor = storeAnchor.nested[o];
      const tier = ccAnchor.store_tier;
      if (!tierData[tier]) {
        tierData[tier] = {};
      }

      if (!tierData[tier][storeAnchor.uuid]) {
        tierData[tier][storeAnchor.uuid] = {
          ...storeAnchor,
          id: storeAnchor.id,
          tier,
          warehouse_id: ccAnchor?.warehouse_id,
          uuid: storeAnchor.uuid,
          nested: [ccAnchor],
          class: ccAnchor?.class || ccAnchor?.klass,
          description: ccAnchor?.description,
          is_hot: ccAnchor?.is_hot,
          start_ship_date: ccAnchor?.start_ship_date,
        };
      } else {
        tierData[tier][storeAnchor.uuid].nested.push(ccAnchor);
      }
    }
  }
  for (let i = 0; i < Object.keys(tierData).length; i += 1) {
    const tier = Object.keys(tierData)[i];
    const tierRows = Object.values(tierData[tier]);
    for (let o = 0; o < tierRows.length; o += 1) {
      const row: any = tierRows[o];
      tierData[tier][row.uuid].unit_qty = row.nested.reduce((acc: any, curr: any) => acc + curr.allocated_qty, 0);
      tierData[tier][row.uuid].cc_qty = row.nested.filter((
        v: any, k: any, a: any,
      ) => a.findIndex((t: any) => t.cc === v.cc) === k).length;
      tierData[tier][row.uuid].childRender = (
        <div className="expanded-row-child">
          <DartTable
            fixed
            width="100%"
            height={400}
            headerHeight={0}
            rowHeight={40}
            data={generateChildrenFn(row)}
            columns={generateColumnsFn()}
          />
        </div>
      );
    }
  }
  return tierData;
};

export const storeByTierColumns = (props: {
  editAllowed?: boolean,
  expandedRows?: string[],
  onExpandToggle?: (toggleValue: boolean) => void,
  selectedRows?: number[],
  onSelectToggle?: (toggleValue: string[]) => void,
}): Record<string, any>[] => [
  ...(props?.editAllowed && props?.onSelectToggle ? [{
    key: "checkbox",
    dataIndex: "checkbox",
    title: "",
    width: 50,
    flexGrow: 1,
    cellRenderer: (rowProps: any) => {
      return (
        <div className="readiness-table-cell uuid-cell">
          {SelectableCell({
            ...rowProps,
            column: {
              selectedRowKeys: props?.selectedRows || [],
              onChange: (selectionData: any) => props?.onSelectToggle?.(selectionData) || false,
              rowKey: rowProps?.rowData?.id,
            },
          }, true)}
        </div>
      );
    },
  }] : []),
  {
    key: "id",
    dataIndex: "id",
    title: "",
    width: 48,
    flexGrow: 1,
    align: "right",
    cellRenderer: () => {
      return "";
    },
  },
  {
    key: "uuid",
    dataIndex: "uuid",
    title: "",
    width: 500,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell uuid-parent-cell">
          {rowData.uuid || NAComponent}
        </div>
      );
    },
  },
  {
    key: "info",
    dataIndex: "info",
    title: "",
    width: 400,
    flexGrow: 2,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-table-cell">
          {`${rowData?.unit_qty || 0} units of ${rowData?.cc_qty || 0} CCs`}
        </div>
      );
    },
  },
];

export const storeByTierChildColumns = (props: {
  row: any,
  onTotalUpdate: (process_id: number, skus: any[], store_id: string, total: number) => void,
  onHotUpdate: (process_id: number, skus: any[], store_id: string, is_hot: boolean) => void,
  onStartShipDateUpdate: (process_id: number, skus: any[], store_id: string, start_ship_date: string | null) => void,
  editable: boolean,
  orderType?: keyof typeof orderTypes,
}): Record<string, any>[] => [
  {
    key: "uuid",
    dataIndex: "uuid",
    title: "",
    width: 220,
    flexGrow: 2,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell uuid-child-cell">
          {rowData?.warehouse_id && <Tag>{rowData?.warehouse_id}</Tag>}
          {rowData.uuid || NAComponent}
        </div>
      );
    },
  },
  {
    key: "division",
    dataIndex: "division",
    title: "Division",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell">
          <div className="inline-header">Division</div>
          {rowData.division || NAComponent}
        </div>
      );
    },
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell">
          <div className="inline-header">Department</div>
          {rowData.department || NAComponent}
        </div>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    width: 150,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell">
          <div className="inline-header">Description</div>
          {rowData.description || NAComponent}
        </div>
      );
    },
  },
  {
    key: "class",
    dataIndex: "class",
    title: "Class",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell">
          <div className="inline-header">Class</div>
          {rowData.class || rowData.klass || NAComponent}
        </div>
      );
    },
  },
  ...(props?.orderType !== orderTypes.cross_dock && (props?.onHotUpdate || props?.onStartShipDateUpdate) ? [
    {
      key: "is_hot",
      dataIndex: "is_hot",
      title: "Is Hot",
      width: 60,
      flexGrow: 1,
      align: "center",
      cellRenderer: ({ rowData }: any) => {
        const is_hot = (rowData?.skus || [])?.[0]?.is_hot;

        return (
          <div className="review-ccs-table-cell cursor-pointer">
            <div className="inline-header">Is Hot</div>
            {is_hot ? (
              <Icon
                name="Check"
                color="#E87324"
                onClick={() => props?.onHotUpdate?.(
                  rowData.skus?.[0]?.process_id, rowData.skus, rowData?.id, false,
                )}
              />
            ) : (
              <Icon
                name="Close"
                color="#959A9D"
                onClick={() => props?.onHotUpdate?.(
                  rowData.skus?.[0]?.process_id, rowData.skus, props.row?.store_id || rowData.skus?.[0]?.store_id, true,
                )}
              />
            )}
          </div>
        );
      },
    },
    {
      key: "start_ship_date",
      dataIndex: "start_ship_date",
      title: "Start Ship Date",
      width: 150,
      flexGrow: 1,
      align: "center",
      cellRenderer: ({ rowData }: any) => {
        const startShipDate = (rowData?.skus || [])?.[0]?.start_ship_date;

        return (
          <div className="review-ccs-table-cell">
            <div className="inline-header">Start Ship Date</div>
            <DatePicker
              placeholder="N/A"
              style={{ width: "130px" }}
              onChange={(dateValue) => props?.onStartShipDateUpdate?.(
                rowData.skus?.[0]?.process_id,
                rowData.skus,
                props.row?.store_id || rowData.skus?.[0]?.store_id,
                dateValue ? dateValue.format("YYYY-MM-DD") : null,
              )}
              value={!startShipDate ? null : moment(startShipDate)}
              allowClear
            />
          </div>
        );
      },
    },
  ] : []),
  {
    key: "total",
    dataIndex: "total",
    title: "Total",
    width: 150,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="review-ccs-table-cell">
          <div className="inline-header">Total</div>
          <EditableCell
            editable={props?.editable}
            cellData={rowData?.total || 0}
            onOk={(qty) => props?.onTotalUpdate?.(
              rowData.skus?.[0]?.process_id, rowData.skus, props.row?.store_id || rowData.skus?.[0]?.store_id, qty,
            )}
            min={rowData?.skus?.length}
            cancelOnBlur
          />
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default getDataViewByStore;
