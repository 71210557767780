import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Button, Form, Tooltip,
} from "antd";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import moment from "moment";
import DartButton from "../../../../components/DartButton";
import { removeNullValues } from "../../../../utils/helpers";
import { NewnessDropdownFiltersBlock, NewnessPillFiltersBlock } from "../..";
import { globalFiltersAtom, newnessDashboardFiltersAtom } from "../../../../global/atoms";
import tooltipValidationMessages from "../../../../utils/validations/tooltipValidationMessages";
import Icon from "../../../../components/Icon";
import paths from "../../../../configs/paths";
import CompactSelect from "../../../../components/CompactSelect";
import DartDatepicker from "../../../../components/DartDatepicker";
import { orderTypes } from "../../../../configs/constants";

interface INewnessFilters {
  onFiltersUpdate?: (filterData?: any) => void;
  onSubmit?: (values?: any) => void;
  canSubmit?: boolean;
  isLoading?: boolean;
  isNewProcess?: boolean;
}

const NewnessFilters: React.FC<INewnessFilters> = ({
  onFiltersUpdate,
  onSubmit,
  canSubmit = false,
  isLoading = false,
  isNewProcess = false,
}) => {
  const [form] = Form.useForm();
  const selectedFilters = useRecoilValue<any>(newnessDashboardFiltersAtom);
  const globalSelectedFilters = useRecoilValue<any>(globalFiltersAtom);
  const [formData, setFormData] = useState<any>(selectedFilters || {});

  // When form data of filters are changed, fire prop function onFiltersUpdate
  useEffect(() => {
    onFiltersUpdate?.(removeNullValues({
      ...formData, brand: globalSelectedFilters?.brand, channel: globalSelectedFilters?.channel,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, globalSelectedFilters]);

  // validate and check data of filter form field before sending the request
  const handleFormUpdateBeforeRequest = (updatedField: any) => {
    setFormData((prev: any) => {
      const isArray = typeof updatedField?.[0]?.value === typeof [];
      let value;

      if (updatedField?.[0]?.name?.[0] === "shipping_date") {
        value = typeof updatedField?.[0]?.value === typeof {} ? updatedField?.[0]?.value?.format("YYYY-MM-DD") : undefined;
      } else if (isArray) {
        value = updatedField?.[0]?.value?.length > 0 ? updatedField?.[0]?.value : undefined;
      } else {
        value = updatedField?.[0]?.value || undefined;
      }

      // Clone previous data
      let prevData = { ...prev };

      // Remove shipping date on order_type change
      if (updatedField?.[0]?.name?.[0] === "order_type") {
        prevData = { ...prev, shipping_date: undefined };
      }

      return { ...prevData, [updatedField?.[0]?.name?.[0]]: value };
    });
  };

  // Handler that calls "onSubmit" prop
  const handleSubmit = (values?: any) => {
    onSubmit?.(values);
  };

  // Memoized value of submit button status. If any required filter is missing disable the button
  const submitEnabled = useMemo(() => {
    const { months } = formData;
    return globalSelectedFilters?.brand && globalSelectedFilters?.channel && months?.length > 0;
  }, [globalSelectedFilters, formData]);

  // Memoized value of tooltip text to show for submit button according to its status
  const decideTooltipText = useMemo(() => {
    if (!submitEnabled) {
      return tooltipValidationMessages.newness_filters_with_months;
    }
    if (!canSubmit) {
      return tooltipValidationMessages.select_newness;
    }
    if (isNewProcess) {
      return tooltipValidationMessages.new_process;
    }
    return "";
  }, [isNewProcess, canSubmit, submitEnabled]);

  useEffect(() => {
    // auto-select after 3 months if order type is cross_dock (orderTypes.cross_dock)
    if (!selectedFilters?.order_type) {
      form.setFieldsValue({ order_type: orderTypes.regular });
    } else if (selectedFilters?.order_type === orderTypes.cross_dock) {
      if (!selectedFilters?.shipping_date) {
        form.setFieldsValue({ shipping_date: moment().add(3, "months").add(1, "day") });
        setFormData((prev: any) => ({
          ...prev,
          shipping_date: moment().add(3, "months").add(1, "day").format("YYYY-MM-DD"),
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const isProduction = process.env.REACT_APP_BASE_URL?.indexOf(".dev.") === -1; // @TODO: temp check to remove from prod

  return (
    <div className="newness-filters">
      <Link to={paths.newness_allocation_history}>
        <Button
          className="history-button"
          ghost
          icon={<Icon name="Refresh" />}
        >
          Allocation History
        </Button>
      </Link>
      <Form
        form={form}
        onFinish={(values) => {
          setFormData(removeNullValues(values));
          handleSubmit(values);
        }}
        initialValues={{
          ...formData,
          ...(formData.order_type === orderTypes.cross_dock ? { shipping_date: moment(formData.shipping_date) } : {}),
        }}
        onFieldsChange={handleFormUpdateBeforeRequest}
      >
        <NewnessDropdownFiltersBlock />
        <div className={`order-type-selection ${formData.order_type === orderTypes.cross_dock ? "with-vertical-offset" : ""}`}>
          {!isProduction ? (
            <Form.Item name="order_type">
              <CompactSelect
                disabled={isLoading}
                placeholder="Order Type"
                selectOptions={[
                  { label: "Regular Order", value: orderTypes.regular },
                  { label: "Cross-Dock Order", value: orderTypes.cross_dock },
                ]}
                canClear={false}
              />
            </Form.Item>
          ) : ""}
          {formData.order_type === orderTypes.cross_dock ? (
            <Form.Item
              key={formData.order_type}
              name="shipping_date"
            >
              <DartDatepicker
                disabled={isLoading}
                placeholder="Shipping Date"
                pickerProps={{
                  allowClear: false,
                  disabledDate: (current: any) => {
                    return current
                      && (current.isBefore(moment().add(3, "months"))
                        || current.isAfter(moment().add(9, "months")));
                  },
                }}
              />
            </Form.Item>
          ) : <></>}
        </div>
        <div className="allocation-action-wrapper">
          <NewnessPillFiltersBlock disabled={isLoading} />
          <Tooltip
            overlay={decideTooltipText}
            placement="bottom"
          >
            <div className="action-button-wrapper">
              <DartButton
                disabled={!submitEnabled || !canSubmit}
                onClick={() => form.submit()}
                label="Proceed"
                size="lg"
                loading={isLoading}
              />
            </div>
          </Tooltip>
        </div>
      </Form>
    </div>
  );
};

export default NewnessFilters;
